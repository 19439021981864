import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import getVariantCollectionId from '~/composables/utils/getVariantCollectionId'

const useFavorites = () => {
  const { id: customerId, hasPlacedOrder } = useCustomer()
  const collectionId = ref('')

  watchEffect(async () => {
    if (customerId.value && hasPlacedOrder.value) {
      collectionId.value = await getVariantCollectionId(
        customerId.value,
        'default-variant-collection'
      )
    }
  })

  return useVariantCollection(collectionId)
}

export default createSharedComposable(useFavorites)
