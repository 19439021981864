<script setup lang="ts">
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

const { isBusy, addVariant, variantExists } = useWaitlist()

const props = defineProps({
  variantId: {
    type: String,
    required: true,
  },
})

const isAwaiting = ref<boolean>(false)
const isError = ref<boolean>(false)

const buttonIcon = computed(() => {
  if (isAwaiting.value) {
    return 'spinner'
  }
  if (isError.value) {
    return 'alert'
  }
  return undefined
})

const success = computed(() => variantExists(props.variantId))

const text = computed(() => {
  if (success.value) {
    return 'You’re on the waitlist!'
  }
  return 'Notify Me'
})

const onClick = async () => {
  if (isAwaiting.value) return
  isAwaiting.value = true
  isError.value = false
  try {
    await addVariant(props.variantId)
  } catch (error: any) {
    if (error instanceof Error) {
      isError.value = true
      const { $sentry } = useNuxtApp()
      $sentry.captureException(error, {
        tags: {
          level: SeverityLevel.FATAL,
          handling: HandlingLevel.CAUGHT_ONLY,
        },
      })
    }
  }
  isAwaiting.value = false
}
</script>
<template>
  <SkyButton
    :aria-pressed="success"
    :class="{
      WaitlistButton: true,
      'WaitlistButton-busy': isBusy,
    }"
    :disabled="isAwaiting || isBusy"
    :left-icon="buttonIcon"
    :success="success"
    block
    data-cnstrc-btn="add_to_wishlist"
    dense
    outlined
    secondary
    @click.prevent="onClick"
  >
    {{ text }}
  </SkyButton>
</template>
<style lang="scss">
.WaitlistButton {
  &-busy {
    visibility: hidden;
  }
}
</style>
