<script setup lang="ts">
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

const { isBusy, addVariant, removeVariant, variantExists } = useFavorites()

const props = defineProps({
  variantId: {
    type: String,
    required: true,
  },
})

const isAwaiting = ref<boolean>(false)
const isPulse = ref<boolean>(false)

const isFavorite = computed<boolean>(() => variantExists(props.variantId))

const onClick = async () => {
  if (isAwaiting.value) return
  isAwaiting.value = true

  try {
    if (isFavorite.value) {
      await removeVariant(props.variantId)
    } else {
      await addVariant(props.variantId)
      isPulse.value = true
    }
  } catch (error: any) {
    if (error instanceof Error) {
      const { $sentry } = useNuxtApp()
      $sentry.captureException(error, {
        tags: {
          level: SeverityLevel.FATAL,
          handling: HandlingLevel.CAUGHT_ONLY,
        },
      })
    }
  }

  isAwaiting.value = false
}
</script>
<template>
  <SkyButton
    :aria-pressed="isFavorite"
    :class="{
      AddToFavoritesIconButton: true,
      'AddToFavoritesIconButton-busy': isBusy,
      'AddToFavoritesIconButton-pulse': isPulse,
    }"
    :disabled="isAwaiting || isBusy"
    icon
    @animationend="isPulse = false"
    @click.prevent="onClick"
  >
    <SkyIcon
      :name="isFavorite ? 'heart-filled' : 'heart'"
      :title="isFavorite ? 'Remove from Favorites' : 'Add to Favorites'"
      size="24px"
    />
  </SkyButton>
</template>
<style lang="scss">
.AddToFavoritesIconButton {
  @keyframes pulse {
    50% {
      transform: scale(1.25);
    }
    100% {
      transform: scale(1);
    }
  }
  --text-color-action-disabled: var(--text-color-action-primary-hover);

  &-busy {
    visibility: hidden;
  }
  &-pulse {
    animation-name: pulse;
    animation-duration: 500ms;
  }
}
</style>
