import { ref } from 'vue'
import type { Ref } from 'vue'

import { useNuxtApp } from '#app'
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

import {
  type RecommendationQuery,
  type CatalogItem,
  type ConstructorResponse,
} from '~/types/catalog'

import useCustomer from '~/composables/useCustomer'

import {
  buildResult,
  FILTER_PARAM,
  requestUrl as buildRequestUrl,
} from '~/composables/utils/constructor-io'
import { resolveRef } from '~/utils/resolveRef'

const DEFAULT_LIMIT = '10'

const useCatalogRecommendations = () => {
  const { id: customerId, locationId } = useCustomer()
  const { $sentry } = useNuxtApp()

  const isBusy = ref(true)
  const resultId = ref<String | undefined>()
  const results: Ref<CatalogItem[]> = ref([])
  const totalNumResults = ref(0)

  /**
   * Fetch product recommendations from the catalog.
   */
  const fetchRecommendations = async (
    id: string,
    queryParams: RecommendationQuery = {}
  ): Promise<void> => {
    isBusy.value = true

    const numResults = queryParams.numResults
      ? String(queryParams.numResults)
      : DEFAULT_LIMIT

    if (!queryParams.filters) {
      queryParams.filters = {}
    }

    const requestUrl = buildRequestUrl()

    try {
      await resolveRef({
        ref: customerId,
        timeout: 5000,
      })

      requestUrl.searchParams.set('ui', String(customerId.value))

      if (locationId.value) {
        requestUrl.searchParams.set('filters[in_stock]', locationId.value)
      } else {
        requestUrl.searchParams.set('filters[any_in_stock]', 'True')
      }

      const userSegments = [
        // Customer location
        `location_${locationId.value || 'none'}`,
      ]

      // Add user segments to the request
      for (const segment of userSegments) {
        requestUrl.searchParams.append('us', segment)
      }

      // Constructor also needs the user segments as a global variable
      window._constructorio_segments = userSegments
    } catch {
      // Do not block results if customer segments fail to resolve
    }

    // The "browse" id allows for a more manual curated list of product recommendations
    // by category or variant IDs.
    if (id === 'browse') {
      requestUrl.searchParams.set('offset', '0')
      requestUrl.searchParams.set('num_results_per_page', numResults)

      if (queryParams.category) {
        requestUrl.pathname = `${id}/group_id/${queryParams.category}`
      } else if (queryParams.variantIds) {
        requestUrl.pathname = `${id}/variation_id/*`
        queryParams.filters.variation_id = queryParams.variantIds
      } else {
        throw new TypeError('category or variantIds field required for browse')
      }
    } else {
      requestUrl.searchParams.set('num_results', numResults)

      if (queryParams.itemIds?.length) {
        for (const itemId of queryParams.itemIds) {
          requestUrl.searchParams.append('item_id', itemId)
        }
      }

      if (queryParams.term) {
        requestUrl.searchParams.set('term', queryParams.term)
      }

      requestUrl.pathname = `/recommendations/v1/pods/${id}`
    }

    for (const [key, value] of Object.entries(queryParams.filters)) {
      const name = `${FILTER_PARAM}[${key}]`
      if (Array.isArray(value)) {
        value.forEach((valueItem) => {
          requestUrl.searchParams.append(name, String(valueItem))
        })
      } else {
        requestUrl.searchParams.set(name, String(value))
      }
    }

    const response = await fetch(requestUrl, {
      headers: {
        Accept: 'application/json',
      },
    })

    if (response.ok && response.headers.get('content-type')?.includes('json')) {
      const {
        response: data,
        result_id: resultIdx,
      }: { response: ConstructorResponse; result_id?: string } =
        await response.json()
      const { results: items = [], total_num_results: totalResults = 0 } = data
      resultId.value = resultIdx
      results.value = items.map(buildResult)
      totalNumResults.value = totalResults
    } else {
      const error = new Error('Constructor recommendations failed')
      $sentry.setContext('response', response)
      $sentry.captureException(error, {
        tags: {
          level: SeverityLevel.ERROR,
          handling: HandlingLevel.CAUGHT_ONLY,
        },
      })
    }

    isBusy.value = false
  }

  return {
    fetchRecommendations,
    isBusy,
    resultId,
    results,
    totalNumResults,
  }
}

/**
 * Use catalog recommendations composable.
 *
 * @returns {useCatalogRecommendations} Catalog Results Composable.
 */
export default useCatalogRecommendations
