import * as JSONAPI from 'jsonapi-typescript'
import getCSRFToken from '~/composables/utils/getCSRFToken'

class VariantCollectionError extends Error {
  name: 'VariantCollectionError'
  response: Response
  constructor(message: string, response: Response) {
    super()
    this.name = 'VariantCollectionError'
    this.message = message
    this.response = response
  }
}

const useVariantCollection = (variantCollectionId: Ref) => {
  const isBusy = ref(true)
  const variantCollection = ref<
    {
      id: string
      variantId: string
    }[]
  >([])

  const variantIds = computed(() =>
    variantCollection.value.map((item) => item.variantId)
  )

  const variantExists = (variantId: string) =>
    variantIds.value.includes(variantId)

  const fetchCollection = async () => {
    isBusy.value = true
    const requestUrl = new URL(
      `/api/variant-collection/${variantCollectionId.value}/items/`,
      location.origin
    )
    requestUrl.searchParams.set('fields[variant-collection-item]', 'variant')

    const response = await fetch(requestUrl, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })

    if (
      response.ok &&
      response.headers?.get('content-type')?.includes('json')
    ) {
      const { data }: { data: JSONAPI.ResourceObject[] } = await response.json()

      variantCollection.value = data
        .map((item) => {
          const variantData = (
            item.relationships?.variant as
              | JSONAPI.RelationshipsWithData
              | undefined
          )?.data as JSONAPI.ResourceIdentifierObject | undefined
          if (item.id && variantData?.id) {
            return {
              id: String(item.id),
              variantId: String(variantData?.id),
            }
          }
          return undefined
        })
        .filter((val) => val !== undefined)
    }
    isBusy.value = false
  }

  /**
   *
   * @param variantId
   */
  const addVariant = async (variantId: string) => {
    if (variantId && variantCollectionId.value && !variantExists(variantId)) {
      const headers = new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-CSRFToken': getCSRFToken(),
      })

      const response = await fetch('/api/variant-collection-item/', {
        method: 'POST',
        headers,
        body: JSON.stringify({
          data: {
            type: 'variant-collection-item',
            relationships: {
              variantCollection: {
                data: {
                  type: 'variant-collection',
                  id: variantCollectionId.value,
                },
              },
              variant: {
                data: {
                  type: 'variant',
                  id: variantId,
                },
              },
            },
          },
        }),
      })

      if (
        response.ok &&
        response.headers?.get('content-type')?.includes('json')
      ) {
        const { data }: { data: JSONAPI.ResourceObject } = await response.json()
        variantCollection.value.push({
          id: String(data.id),
          variantId,
        })
      } else {
        throw new VariantCollectionError('Failed to add variant', response)
      }
    }
  }

  const removeVariant = async (variantId: string) => {
    const item = variantCollection.value.find(
      (item) => item.variantId === variantId
    )
    if (item) {
      const headers = new Headers({
        'X-CSRFToken': getCSRFToken(),
      })

      const requestUrl = new URL(
        `/api/variant-collection-item/${item.id}/`,
        location.origin
      )

      const response = await fetch(requestUrl, {
        method: 'DELETE',
        headers,
      })

      if (response.ok) {
        variantCollection.value = variantCollection.value.filter(
          ({ id }) => id !== item.id
        )
      } else {
        throw new VariantCollectionError('Failed to remove variant', response)
      }
    }
  }

  watch(
    variantCollectionId,
    () => {
      variantCollection.value = []
      if (variantCollectionId.value) {
        fetchCollection()
      }
    },
    { immediate: true }
  )

  return {
    addVariant,
    isBusy,
    removeVariant,
    variantExists,
    variantIds,
  }
}

export default useVariantCollection
