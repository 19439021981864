<script setup lang="ts">
import { HandlingLevel, SeverityLevel } from '@/plugins/sentry.client'

const props = defineProps({
  variantId: {
    type: String,
    required: true,
  },
  outOfStock: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether customer wishes to subscribe or not subscribe to product.
   */
  subscribe: {
    type: Boolean,
    default: null,
  },

  // Attributional props
  // The majority of these are no longer used
  // but are still required by http-events.
  /**
   * Navigational origin of atc view.
   * @see CartAddContext.legacy.source
   */
  source: {
    type: Number,
    default: 10,
  },
  /**
   * Position of view detail featuring atc button.
   * @see CartAddContext.detail.position
   */
  position: {
    type: Number,
    default: null,
  },
  /**
   * Optional content source code to override the globally defined content source.
   * @see CartAddContext.content.source
   */
  customContentSource: {
    type: String,
    default: null,
  },
  /**
   * Optional content source title to override the globally defined content source.
   * @see CartAddContext.content.title
   */
  customContentSourceTitle: {
    type: String,
    default: null,
  },
  /**
   * Optional content source id to override the globally defined content source.
   * @see CartAddContext.content.entryId
   */
  customContentSourceId: {
    type: String,
    default: null,
  },
  /**
   * A code specifying the display origin of the atc button.
   * @see CartAddContext.detail.source
   */
  detailSource: {
    type: String,
    default: null,
  },
  /**
   * Copy identifying detail source entry. Usually heading of entry.
   * @see CartAddContext.detail.sourceName
   */
  detailSourceName: {
    type: String,
    default: null,
  },
  /**
   * If the atc button is for a featured product review.
   * @see CartAddContext.isFeaturedProduct
   */
  isFeaturedProduct: {
    type: Boolean,
    default: false,
  },
})

const { id: cartId, items, createCartItem, updateCartItem } = useCart()
const { $events } = useNuxtApp()

const successMessages = [
  'Force of nature!',
  'Good choice!',
  'Naturally!',
  'Planet Hero!',
]

const success = ref(false)

const text = computed(() => {
  if (props.outOfStock) {
    return 'Out of Stock'
  }

  if (success.value) {
    return successMessages[Math.floor(Math.random() * successMessages.length)]
  }

  return 'Add to Cart'
})

watch(success, (value) => {
  if (value) {
    setTimeout(() => {
      success.value = false
    }, 3500)
  }
})

const onClick = async () => {
  success.value = true
  // If variant is already in cart, do not attempt add.
  const cartItem = items.value.find(
    (item) => item.variantId === props.variantId
  )
  try {
    if (cartItem) {
      // Refresh cart item if subscribe state changes when item is already in the cart.
      if (props.subscribe !== null) {
        await updateCartItem({
          id: cartItem.id,
        })
      }
    } else {
      const addedItem = await createCartItem({
        source: props.source,
        variantId: props.variantId,
      })

      // TODO: Create or remove Subscription intent based in `subscribe` prop.
      // Once `useSubscriptionIntent` is ported. Only used on PDP ATC.

      const atcContexts = {
        id: cartId.value || 'none',
        item: {
          id: addedItem.id,
          priceCents: addedItem.offerPrice,
          product: {
            id: addedItem.productId,
            isSubscribed: Boolean(props.subscribe),
            name: addedItem.productName,
            brand: addedItem.brandName || '',
            detailsUrl: new URL(
              addedItem.productUrl,
              location.origin
            ).toString(),
            categories: [],
          },
          variant: {
            id: addedItem.variantId,
            name: addedItem.variantName || '',
            imageUrl: addedItem.image,
            sku: addedItem.variantSku,
          },
          quantity: addedItem.quantity,
          cartId: cartId.value,
        },
        add: {
          legacy: {
            source: props.source,
            attrSource: null,
            attrPosition: null,
            attrPage: 'self',
          },
          content: {
            source: props.customContentSource || null,
            title: props.customContentSourceTitle || null,
            entryId: props.customContentSourceId || null,
          },
          detail: {
            source: props.detailSource,
            sourceName: props.detailSourceName,
            position: props.position,
          },
          isFeaturedProduct: props.isFeaturedProduct,
          isSideEffect: false,
          // FOPS-84: For PDP adds only, specify subscription intent in analytics
          subscriptionIntent: null,
          // props.source === sourceCodes.PRODUCT_DETAILS
          //   ? await getAddToCartSubscriptionIntent(this.variantId, this.subscribe)
          //   : null
        },
      }
      $events.emit(...$events.cartAddItem(atcContexts))
    }
  } catch (error: any) {
    success.value = false
    if (error instanceof Error) {
      const { $sentry } = useNuxtApp()
      $sentry.captureException(error, {
        tags: {
          level: SeverityLevel.FATAL,
          handling: HandlingLevel.CAUGHT_ONLY,
        },
      })
    }
  }
}
</script>
<template>
  <SkyButton
    :disabled="outOfStock"
    :success="success"
    block
    dense
    data-cnstrc-btn="add_to_cart"
    data-test-id="add-to-cart-button"
    @click.prevent="onClick"
  >
    <slot :success="success">{{ text }}</slot>
  </SkyButton>
</template>
