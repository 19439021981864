import * as JSONAPI from 'jsonapi-typescript'

/**
 * Ideally our API would just return the variant collection ids
 * associated with the customer in the customer API response.
 * For now we must make an additional request.
 */
const getVariantCollectionId = async (
  customerId: string,
  collectionName: string
): Promise<string> => {
  const requestUrl = new URL(
    `/api/customer/${customerId}/${collectionName}/`,
    location.origin
  )
  requestUrl.searchParams.set('fields[variant-collection]', 'id')

  const response = await fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  })

  if (response.ok && response.headers?.get('content-type')?.includes('json')) {
    const { data }: { data: JSONAPI.ResourceObject } = await response.json()
    return String(data.id)
  }

  return ''
}

export default getVariantCollectionId
